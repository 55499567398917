
import { Options, Vue } from "vue-class-component";
import { INote } from "@/types/Note";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import Breadcrumb from "@/components/Breadcrumb.vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import DropdownComponent from "@/components/DropdownComponent.vue";
import { notify } from "@/services/helpers";

@Options({
  name: "EditNote",
  components: {
    Breadcrumb,
    Form,
    Field,
    ErrorMessage,
    ConfirmationModal,
    Dropdown: DropdownComponent,
  },
  data: function () {
    return {
      schema: yup.object().shape({
        name: yup
          .string()
          .required("Please enter a subject.")
          .max(50, "Must not exceed 20 characters in length."),
        detail: yup
          .string()
          .required("Please provide a detailed description.")
          .max(200, "Must not exceed 200 characters in length."),
        status: yup.string().required("Please select a status."),
      }),
    };
  },
  props: {},
})
export default class EditNote extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  editNote: INote = {
    id: 0,
    name: "",
    detail: "",
    status: "",
    employeeId: 0,
    createdOn: "",
  };
  dropdownData: any = [
    { value: "Employee", Type: "Employee" },
    { value: "Private", Type: "Private" },
    { value: "Incident", Type: "Incident" },
  ];
  crumbs: any = ["Dashboard", "Employees", "Notes", "Edit Note"];
  loading = false;

  async goToRoute(route: string) {
    await this.$router.push(route);
  }

  async created() {
    await this.initialize();
  }

  async initialize() {
    const noteId = this.$route.params.noteId;
    this.workForceService
      .get<INote>(`/notes/${noteId}`, false)
      .then((response: any) => {
        this.editNote = response.content;
      });
  }

  message = "";
  async save() {
    this.loading = true;
    this.message = "";
    this.workForceService
      .postOrPut<void>(
        `/notes/${this.editNote.id}`,
        this.editNote as INote,
        this.editNote.id.toString()
      )
      .then((response: any) => {
        this.loading = false;
        if (!response.isError) {
          notify("Note updated successfully.", "Success", "success");
          this.goToRoute(`/admin/notes/${this.editNote.employeeId}`);
        } else {
          notify(response.errors, "Error", "danger");
        }
      });
  }
}
